<template>
  <ion-app :class="baseClass()">
    <!--      <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">-->
    <!--        An update is available-->
    <!--        <v-btn text @click="refreshApp">-->
    <!--          Update-->
    <!--        </v-btn>-->
    <!--      </v-snackbar>      -->
    <div class="update-available" v-if="updateExists">
      {{ $t("update.available") }}<br />
      <ion-button color="primary" @click="refreshApp">{{
        $t("update.update")
      }}</ion-button>
    </div>
    <h4 class="not-mobile">
      {{ $t("generic.template_for_mobile") }}
    </h4>
    <template>
      <!--      <ion-loading-->
      <!--          :is-open="loading"-->
      <!--          cssClass="my-custom-class"-->
      <!--          :duration="50000"-->
      <!--      >-->
      <!--      </ion-loading>-->
    </template>

    <Header></Header>
    <ion-router-outlet :key="$route.path" id="main-content" />
    <div v-if="chatOverlayVisible" class="chat-overlay"></div>
    <TalkjsTrigButton v-if="whappyClientConfig.name == 'Axerta' && $route.path !== '/axerta/lista-ordini' "
      @toggle-chat="toggleChatOverlay"
      class="talkjs-chat-button"
    />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonButton,
  IonRouterOutlet,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { IonLoading } from "@ionic/vue";
import Header from "./components/Header.vue";
import { mapGetters, mapState } from "vuex";
import update from "./common/mixins/Update";
import { useRoute } from "vue-router";
import TalkjsTrigButton from "@/components/chat/TalkjsTrigButton.vue";

export default defineComponent({
  name: "App",
  inheritAttrs: false,
  mixins: [update],
  components: {
    Header,
    IonApp,
    IonLoading,
    IonButton,
    IonRouterOutlet,
    TalkjsTrigButton,
  },
  data() {
    return {
      chatOverlayVisible: false,
    };
  },
  created() {
    this.$root.localStorage.create();
    window.loadingCPresented = null;
  },
  methods: {
    baseClass() {
      return "route-" + useRoute().name;
    },
    toggleChatOverlay(visible) {
      this.chatOverlayVisible = visible; // Gestione dell'overlay
    },
    async presentLoading() {
      window.loadingCPresenting = true;
      if (!window.loadingC) {
        window.loadingC = await loadingController.create({
          cssClass: "my-custom-loading",
          message: "Please wait...",
          // duration: this.timeout,
        });
      }
      if (!window.loadingCPresented) {
        await window.loadingC.present();
        window.loadingCPresented = true;
      }
    },
    async dismissLoading() {
      if (window.loadingCPresented) {
        await window.loadingC.dismiss();
        window.loadingCPresenting = false;
        window.loadingCPresented = false;
        // // Sic
        // const elements = document.getElementsByClassName('my-custom-loading');
        // while(elements.length > 0){
        //   elements[0].parentNode.removeChild(elements[0]);
        // }
      } else if (window.loadingCPresenting) {
        setTimeout(() => {
          this.dismissLoading();
        }, 100);
      }
    },
  },
  computed: {
    ...mapGetters("loading", {
      isLoading: "isLoading",
    }),
  },
  watch: {
    isLoading: function (e) {
      if (e) {
        this.presentLoading();
      } else {
        this.dismissLoading();
      }
    },
  },
});
</script>

<style>
/* ion-content {
  --keyboard-offset: 25% !important;
} */

.chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999; /* Sotto la finestra di chat ma sopra il contenuto della pagina */
}

.talkjs-chat-button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}
</style>
